@import "fonts.css";
@import "loader.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sb-theme-color: #151522;
  --sb-main-color: #53B9AB;
  --dark-neutral-gray-12: #EDEEF0;
  --font-family: "Oswald";
  --teal-dark-teal-11: #0BD8B6;
  --dark-neutral-gray-11: #B0B4BA;
  --teal-dark-teal-12:  #ADF0DD;
  --spacer-spacer-0: 0;
  --base-bg-color: #1E1E32;
  --grass-grass-8: #65BA75;
  --gray-tone-color: #EDEEF0;
  --gray-border-17: rgba(94, 94, 94, 0.17);
}


.main {
  background: var(--sb-theme-color);
  color: var(--dark-neutral-gray-12, #EDEEF0);
  font-family: var(--font-family);
  min-height: 100svh;
}

.bg-main {
  background: var(--sb-theme-color);
}

.auth-title {
  color: var(--dark-neutral-gray-12, #EDEEF0);
  /* Heading 2 */
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
}

.main-title {
  color: var(--dark-neutral-gray-12, #EDEEF0);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px
}

.font-exo {
  font-family: Exo;
}

.btn-primary {
  font-family: Exo;
  border-radius: 4px;
  border: 2px solid #53B9AB;
  background: linear-gradient(180deg, #26AA99 0%, #26AA99 100%);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.main-color {
  color: var(--teal-dark-teal-11);
}

.text-gray-11 {
  color: var(--dark-neutral-gray-11);
}

.bottom-bar .active {
  position: relative;
  color: var(--teal-dark-teal-12, #ADF0DD);
  overflow: hidden;
}

.bottom-bar .active::before {
  position: absolute;
  top: 0;
  content: " ";
  width: 102px;
  height: 6px;
  border-radius: var(--spacer-spacer-0, 0px) var(--spacer-spacer-0, 0px) 120px 120px;
  background: var(--teal-dark-teal-11, #0BD8B6);
}

.bottom-bar .active::after {
  position: absolute;
  bottom: -50px;
  height: 34px;
  width: 100%;
  box-shadow: 0 0 5px var(--teal-dark-teal-11),
  0 0 25px var(--teal-dark-teal-11),
  0 0 50px var(--teal-dark-teal-11),
  0 0 200px var(--teal-dark-teal-11);
  content: " ";
}

.scan-qr {
  filter: drop-shadow(0px 2px 4px rgba(164, 221, 210, 0.34)) drop-shadow(0px 10px 24px rgba(17, 166, 137, 0.45));
}

table thead tr th, table tbody tr td {
  color: var(--dark-neutral-gray-11);
}

.my-qr-code svg {
  width: 100%;
}

.history-list li.active {
  border-bottom-color: #0EB39E;
}

.button-custom-shadow {
  box-shadow: 0px 2px 4px 0px rgba(164, 221, 210, 0.34), 0px 10px 24px 0px rgba(17, 166, 137, 0.45);
}

.button-info-custom-shadow {
  box-shadow: 0px 2px 4px 0px rgba(213, 174, 57, 0.35), 0px 10px 24px 0px rgba(213, 174, 57, 0.15);
}

.zIndexTop {
  z-index: 9999;
}

.league-content-tab .customTab:not(.border-transparent) {
  background: transparent;
  color: #777B84;
  border-color: var(--sb-main-color);
}

.league-content-tab .hover\:bg-neutral-100:hover {
  background: transparent;
  border-bottom-color: #0EB39E;
}

.league-content-tab .text-primary {
  color: #EDEEF0;
}

.border-b-custom {
  border-bottom-color: rgba(94, 94, 94, 0.17);
}

.bg-base {
  background: var(--base-bg-color);
}

.background-accent {
  background: var(--sb-main-color);
}

.text-accent {
  color: var(--sb-main-color)
}

.custom-marker .MuiSlider-markLabel {
  color: #ffffff;
}

.custom-marker  .MuiSlider-mark {
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid #B0B4BA;
  background: #EDEEF0;
}

.custom-marker  .MuiSlider-mark[data-index="4"] {
  left: calc(100% - 12px)!important;
}

.custom-marker  .MuiSlider-mark.MuiSlider-markActive {
  border: 4px solid var(--sb-main-color);
}

.custom-marker .css-eg0mwd-MuiSlider-thumb:hover, .custom-marker  .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
  box-shadow: none;
}

.custom-marker .MuiSlider-thumb {
  box-sizing: content-box;
  border-radius: 100%;
  border: 8px solid var(--sb-main-color);
  background: #EDEEF0;
  width: 20px;
  height: 20px;
}

.custom-marker .MuiSlider-rail {
  border-radius: 5px;
  background: #D9D9D9;
  height: 8px;
}

.custom-marker .MuiSlider-track {
  background: var(--sb-main-color);
  border-color: var(--sb-main-color);
}

.divider {
  height: 1px;
  background: rgba(94, 94, 94, 0.17);
  margin: 0.5rem 0;
}

.custom-border-color {
  border-color: var(--border-gray-17);
}

.MuiSwitch-track {
  background: var(--dark-neutral-gray-11)!important;;
}

.league-content-tab .Mui-checked+.MuiSwitch-track {
  background: #0EB39E!important;
  opacity: 1!important;
}

.league-content-tab .MuiSwitch-thumb {
  background: #ffffff;
  width: 14px;
  height: 14px;
  top: -2px;
  margin-top: 3px;
  margin-left: 2px;
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pencil" class="svg-inline--fa fa-pencil " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="silver" d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path></svg>');
}
